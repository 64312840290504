import React, { createContext } from 'react'
import useSetState from 'react-use/lib/useSetState'
import { getClientTimezone } from '@chilipiper/date-time'
import { getLocale } from '@chilipiper/locale'
import { Workspaces } from '@chilipiper/models/src/workspaces'
import {
  Event,
  LocationType,
  ObjectRelate,
  ParticipantByEmailResponse,
  Prospect,
  Template,
} from '@chilipiper/api-type-def'
import { startOfWeek, endOfWeek } from 'date-fns'
import { setTag } from '@chilipiper/sentry'
import {
  CalendarEvent,
  Guest,
  Assignee,
  QueueWithRules,
  WorkspaceWithRole,
  CustomRoom,
  CustomRoomIds,
  InitialViewReturn,
  QueueMember,
} from '../../types'
import { getQueryParam, params } from '../../utils/query-param'
import { selectWorkspace } from '../../service'
import { isEventWorkspace as checkEventWorkspace } from '../../utils/workspaces'
import { inviteeOrStringToGuest, outreachProspectToGuest } from './utils'
import { useGlobalState } from '../../hooks/context'
import { NO_ROOM } from '../../components/right-sidebar/meeting-info/rooms'
import { OutreachProspect } from '../../types/index'

const clientTimezone = getClientTimezone()

export interface State {
  assignee?: Assignee
  bookedMeetingLink?: string
  calendarSelections: CalendarEvent[]
  end: Date
  guests: Guest[]
  handoff?: boolean
  isBookingOnSelf: boolean
  isLoadingQueues: boolean
  isSelectingWorkspace: boolean
  locale: string
  mainGuestDuplicateCRMRecords?: ParticipantByEmailResponse
  isLoadingMainGuestCRMData?: boolean
  nextAssignee?: QueueMember
  noHandoffQueues?: boolean
  prospect?: Guest | Prospect
  reassignBooker?: Guest
  reassignQueueId?: string
  reassignTemplateId?: string
  reassignWorkspaceId?: string
  meetingLocations: LocationType[]
  selectedEvent?: Event
  selectedQueue?: QueueWithRules
  selectedRelatedTo?: { type: ObjectRelate; id: string }
  selectedRoom?: CustomRoom
  selectedTemplate?: Template
  selectedWorkspace?: WorkspaceWithRole
  showAvailabilityForAll?: boolean
  start: Date
  step: number
  timezone: string
  updatedDurationForTemplateId: string
  workspaceId?: string
}

interface BookingStateValue {
  bookingState: State
  setBookingState: (state: Partial<Omit<State, 'prospect'>>) => void
  setProspect: (prospect: Guest | Prospect | undefined) => void
  setWorkspace: (workspace?: WorkspaceWithRole) => void
  setAvailabilityForAll: () => void
  isEventWorkspace: () => boolean
  isAnyRoom: () => boolean
  isNoRoom: () => boolean
  setView: (viewData: InitialViewReturn) => void
}

export const BookingContext = createContext({} as BookingStateValue)

export const mapGuests = (guests: string | null): Guest[] => {
  if (!guests) return []
  return decodeURIComponent(guests).split(',').map(inviteeOrStringToGuest)
}

export const getProspect = (
  prospectEmail: string | undefined,
  prospectName?: string
): Guest | undefined => {
  if (!prospectEmail) return
  const [firstName = '', lastName = ''] = (prospectName ?? '').split(' ')
  const emailDomainRegexp = /.*@(.*)\./g
  const companyName = emailDomainRegexp.exec(prospectEmail)?.[1]
  return {
    email: prospectEmail.toLowerCase(),
    mandatory: true,
    inCrm: false,
    fullName: prospectName ?? '',
    firstName,
    lastName,
    companyName,
  }
}

const browserLocale = getLocale()

interface Props {
  initialState?: Partial<State>
  children: React.ReactNode
}

export const BookingStateProvider: React.FC<Props> = ({ children, initialState }) => {
  const { globalState } = useGlobalState()
  const outreachProspectList = JSON.parse(params.get('eml.to') ?? '[]') as OutreachProspect[]
  let prospectEmail = getQueryParam('prospect') as string
  let prospectName = ((params.get('name') as string | undefined) ?? '').replace('+', ' ')
  let guests = mapGuests(params.get('guests'))
  if (outreachProspectList.length > 0) {
    const prospect = outreachProspectList.pop()
    guests = outreachProspectList.map(outreachProspectToGuest)
    prospectName = prospect?.name ?? ''
    prospectEmail = prospect?.email ?? ''
  }

  const startDate = startOfWeek(initialState?.start || new Date())

  const [state, setState] = useSetState<State>({
    guests,
    step: 30,
    prospect: getProspect(prospectEmail, prospectName),
    calendarSelections: [],
    timezone: clientTimezone,
    meetingLocations: [],
    isBookingOnSelf: false,
    selectedRoom: NO_ROOM,
    handoff: false,
    /*
      Setting loading queues initially as true avoids shifting loaded events from personal to team calendar. This must be explicit set to false in views where we don't have queues
    */
    isLoadingQueues: true,
    isSelectingWorkspace: false,
    locale: browserLocale,
    updatedDurationForTemplateId: '',
    ...initialState,
    start: startDate,
    end: endOfWeek(startDate),
  })

  const setCurrentUserAsAssignee = React.useCallback(() => {
    setState({
      assignee: {
        id: globalState.session.id,
        name: globalState.session.user.displayName,
        email: globalState.session.email,
      },
      showAvailabilityForAll: false,
      isBookingOnSelf: true,
    })
  }, [globalState.session.email, globalState.session.id, globalState.session.user.displayName])

  const setAvailabilityForAll = React.useCallback(() => {
    setState({
      showAvailabilityForAll: true,
      assignee: undefined,
      isBookingOnSelf: false,
      calendarSelections: [],
    })
  }, [])

  const setView = React.useCallback(
    (viewData: InitialViewReturn) => {
      if (viewData.view === 'Assignee' && viewData.member) {
        setState({
          assignee: viewData.member,
          isBookingOnSelf: false,
          showAvailabilityForAll: false,
        })
      }

      if (viewData.view === 'Availability for all') {
        setAvailabilityForAll()
      }

      if (viewData.view === 'My Calendar') {
        setCurrentUserAsAssignee()
      }
    },
    [setAvailabilityForAll, setCurrentUserAsAssignee]
  )

  const setWorkspace = React.useCallback(
    (workspace?: WorkspaceWithRole) => {
      if (workspace) {
        setState({ isSelectingWorkspace: true })
        selectWorkspace(workspace.id).then(() => {
          setState({
            selectedWorkspace: workspace,
            selectedQueue: undefined,
            selectedRoom: undefined,
            selectedRelatedTo: undefined,
            showAvailabilityForAll: false,
            isSelectingWorkspace: false,
          })

          if (
            workspace.isBookingOnSelfCalendar ||
            Workspaces.isMild(workspace) ||
            Workspaces.isFree(workspace)
          ) {
            setView({ view: 'My Calendar' })
          }
        })
      }
    },
    [state.isBookingOnSelf, setView]
  )

  const setProspect = React.useCallback((prospect: Guest | Prospect | undefined) => {
    setTag('prospect', prospect?.email)
    if (!prospect) {
      setState({ prospect })
      return
    }

    setState({ prospect: { ...prospect, mandatory: true } })
  }, [])

  const isEventWorkspace = React.useCallback(() => {
    return checkEventWorkspace(state.selectedWorkspace) && !state.isBookingOnSelf
  }, [state.selectedWorkspace, state.isBookingOnSelf])

  const isAnyRoom = React.useCallback(() => {
    return (state.selectedRoom?.id as CustomRoomIds) === 'ALL_ROOMS'
  }, [state.selectedRoom])

  const isNoRoom = React.useCallback(() => {
    return (state.selectedRoom?.id as CustomRoomIds) === 'NO_ROOM' || !state.selectedRoom?.id
  }, [state.selectedRoom])

  const contextValue = {
    bookingState: state,
    setBookingState: setState,
    setAvailabilityForAll,
    setProspect,
    setWorkspace,
    setView,
    isEventWorkspace,
    isAnyRoom,
    isNoRoom,
  }

  return <BookingContext.Provider value={contextValue}>{children}</BookingContext.Provider>
}
