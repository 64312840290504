import React from 'react'
import { Navigate, NavigateProps } from 'react-router-dom'

export const NavigateWithParams = ({ to, ...props }: NavigateProps) => {
  return (
    <Navigate
      to={{
        pathname: to as string,
        search: window.location.search,
      }}
      {...props}
    />
  )
}
