import { ObjectRelate } from '@chilipiper/api-type-def'
import sortBy from 'lodash/sortBy'
import { QueueMember, QueueWithRules, Assignee } from '../types'

export const isQueuePlaceholder = (queue?: QueueWithRules) => queue?.algorithm === 'PlaceHolder'

export const isQueueStrict = (queue?: QueueWithRules) => {
  return (
    queue?.algorithm === 'RoundRobin' || (queue?.algorithm === 'Ownership' && queue.strictOwnership)
  )
}

export const isQueueOwnership = (queue?: QueueWithRules): queue is QueueWithRules =>
  !isQueueHandoff(queue) && queue?.algorithm === 'Ownership'

export const isQueueGroup = (queue?: QueueWithRules) =>
  !isQueueHandoff(queue) && queue?.algorithm === 'Group'

export const isQueueHandoff = (queue?: QueueWithRules) =>
  queue?.hotHandoff && queue.active && queue.members.length > 0

export const getHandoffMembers = (members: QueueMember[] = []) => {
  return sortBy(
    members.filter(member => member.available),
    member => member.order
  )
}

export const isAssigneeAccessible = (assignee?: Assignee, members?: QueueMember[]) => {
  const assigneeInMembers = members?.find(member => member.id === assignee?.id)
  if (assigneeInMembers) {
    return assigneeInMembers.accessible
  }
  return true
}

export const objectRelateToType = (type: ObjectRelate) => {
  switch (type) {
    case ObjectRelate.Case:
      return 'caseId'
    case ObjectRelate.Opportunity:
      return 'opportunityId'
    default:
      return 'customObjectId'
  }
}
