import { Box, Text } from '@chilipiper/design-system'
import { Session } from '@chilipiper/models'
import React from 'react'

export const SuspendedAccountBanner = () => {
  const session = Session.session()
  const isAccountSuspended = session.accountInstallationStatus === 'Suspended'

  if (!isAccountSuspended) {
    return null
  }

  return (
    <Box p={2} bg='red-dark-100'>
      <Text display='block' textAlign='center' textStyle='cell-heading' color='white'>
        Your Chili Piper Subscription Expired: If you need assistance, please contact your Chili
        Piper admin.
      </Text>
    </Box>
  )
}
