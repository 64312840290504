import { useContext } from 'react'
import { BookingContext } from '../context/booking'
import { CrmContext } from '../context/crm'
import { GlobalContext } from '../context/global'
import { SettingsContext } from '../context/settings'

export const useBookingState = () => {
  return useContext(BookingContext)
}

export const useGlobalState = () => {
  return useContext(GlobalContext)
}

export const useCrmState = () => {
  return useContext(CrmContext)
}

export const useSettingsState = () => {
  return useContext(SettingsContext)
}
