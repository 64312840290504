import React, { useEffect, lazy, useCallback, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query-devtools'
import { useShowSnackbar } from '@chilipiper/design-system'
import { getSubdomain, maybeCanaryRedirect } from '@chilipiper/service/src/canary'
import {
  init as initSentry,
  ErrorBoundary as SentryErrorBoundary,
  withProfiler,
  setTag,
} from '@chilipiper/sentry'
import { Provider as ThemeProvider } from '@chilipiper/design-system'
import { initTracking, setTrackingUserId, trackPageView } from '@chilipiper/service/src/tracking'
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from 'react-router-dom'
import { Session } from '@chilipiper/models/src/session'
import { environment, sentry } from '@chilipiper/config'
import { Workspace } from '@chilipiper/api-type-def'
import { endOfWeek, startOfWeek } from 'date-fns'
import { session as loadSession } from '@chilipiper/service/src/api'
import { useLogRocket, logRocketIdentify, logRocketTrack } from '@chilipiper/tracking'
import * as Adoption from '@chilipiper/service/src/adoption'
import { create } from '@chilipiper/service/src/session'
import { GlobalStateProvider } from './context/global'
import { routes } from './pages/routes'
import { BookingStateProvider } from './context/booking'
import { useBookingState, useGlobalState } from './hooks/context'
import { registerInBackground, sendPingMessage } from './service'
import { CrmStateProvider } from './context/crm'
import {
  useGuestsInfo,
  useReportReassignData,
  useWorkspaces,
  useFindMainGuestInCRM,
} from './hooks/query'
import { NavigateWithParams } from './components/NavigateWithParams'
import { GlobalStyles } from './GlobalStyles'
import { createSelectedEvent } from './components/calendar/helpers'
import { SuspenseWithFallback } from './components/suspense-with-fallback/SuspenseWithFallback'
import { DefaultLoading } from './components/suspense-with-fallback/DefaultLoading'
import { inviteeOrStringToGuest } from './context/booking/utils'
import { SettingsStateProvider } from './context/settings'
import { SuspendedAccountBanner } from './components/suspended-account-banner/SuspendedAccountBanner'

const IntermediaryScreen = lazy(() => import('./pages/intermediary-screen'))
const DuplicateRecords = lazy(() => import('./pages/duplicate-records'))
const Booker = lazy(() => import('./pages/booker/Booker'))
const Handoff = lazy(() => import('./pages/handoff/Handoff'))
const Booked = lazy(() => import('./pages/booked/Booked'))
const SessionError = lazy(() => import('./pages/error/Session'))
const GeneralError = lazy(() => import('./pages/error/General'))
const CanceledMeeting = lazy(() => import('./pages/error/CanceledMeeting'))

initTracking()
Adoption.init()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: 5,
      retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 15000),
    },
  },
})

if (environment.deploy !== 'staging' && environment.node !== 'test') {
  initSentry(sentry.instantBookerNewDSN)
}

export function Main() {
  const navigate = useNavigate()
  const location = useLocation()
  const { setGlobalState, globalState } = useGlobalState()
  const { bookingState, setBookingState, setProspect, setWorkspace, setView } = useBookingState()
  const { data: workspaces, isLoading: isLoadingWorkspaces } = useWorkspaces()
  const { data: guestsInfo = [], isLoading: isLoadingProspect } = useGuestsInfo()
  const { data: reassignData, isLoading: isLoadingReassign } = useReportReassignData()
  const { data: mainGuestCRMData, isLoading: isLoadingMainGuestCRMData } = useFindMainGuestInCRM()
  const showSnackbar = useShowSnackbar()
  const [loadedInitialWorkspace, setLoadedInitialWorkspace] = useState(false)
  const [loadedReassignData, setLoadedReassignData] = useState(false)
  const guestsKeys = guestsInfo.map(guest => guest.id ?? guest.email).join('')

  useLogRocket()

  useEffect(() => {
    if (globalState.code) {
      localStorage.setItem('CP_AT_V2', globalState.code)
      Session.createSession()
      const searchParams = new URLSearchParams(location.search)
      searchParams.delete('code')
      // we need to keep the trailing slash to avoid redirecting to page not found
      navigate(
        { pathname: '//', search: searchParams.toString() },
        {
          replace: true,
        }
      )
    }
  }, [])

  const getRootRoute = () => {
    if (!globalState.session.id || isLoadingMainGuestCRMData) {
      return <DefaultLoading />
    }

    const duplicateRecordsNumber = bookingState.mainGuestDuplicateCRMRecords?.total ?? 1

    if (globalState.skipIntermediaryScreen === true && duplicateRecordsNumber > 1) {
      return <Navigate to={routes.duplicateRecords} />
    }
    if (globalState.reportId || globalState.skipIntermediaryScreen === true) {
      return <NavigateWithParams to={routes.booker} />
    }
    return <NavigateWithParams to={routes.intermediary} />
  }

  useEffect(() => {
    trackPageView()
  }, [location])

  useEffect(() => {
    const timer = setInterval(() => {
      sendPingMessage()
    }, 5000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  const hasProspect = useCallback(() => {
    return bookingState.prospect || globalState.reportId || globalState.id || globalState.caseId
  }, [bookingState.prospect, globalState.reportId, globalState.id])

  useEffect(() => {
    async function initSession() {
      try {
        const session = await loadSession(true)
        create(session)
        setGlobalState({ session })
        maybeCanaryRedirect(session.subDomain ?? getSubdomain())

        const userId = Session.getUserId()
        logRocketIdentify({
          id: userId,
          email: Session.getEmail(),
          tenantId: Session.getTenantId(),
        })
        Adoption.identify(userId, {
          email: Session.getEmail(),
          tenantId: Session.getTenantId(),
        })
        setTrackingUserId(Session.getEmail())
        setTag('account', Session.getDomain())
        setTag('userId', userId)
      } catch (err) {
        navigate(routes.error.session)
      }
    }
    initSession()
  }, [])

  useEffect(() => {
    if (globalState.isExtension) {
      registerInBackground()
    }

    const logRocketProps: Record<
      string,
      string | number | boolean | string[] | number[] | boolean[] | undefined
    > = {
      isExtension: globalState.isExtension,
    }

    bookingState.guests.forEach((guest, index) => {
      Object.entries(guest).forEach(([name, value]) => {
        logRocketProps[`guest.${index}.${name}`] = JSON.stringify(value)
      })
    })

    if (bookingState.prospect) {
      Object.entries(bookingState.prospect).forEach(([name, value]) => {
        logRocketProps[`prospect.${name}`] = JSON.stringify(value)
      })
    }

    logRocketTrack('Loaded Instant Booker 3.0', logRocketProps)
  }, [bookingState.prospect, globalState.isExtension])

  useEffect(() => {
    /*
      Set initial workspace from session or report data if available.
    */
    const selectedWorkspaceInSession = globalState.session.workspaces.selected
    if (!isLoadingWorkspaces && !loadedInitialWorkspace && workspaces) {
      let workspace: Workspace | undefined = undefined

      if (bookingState.reassignWorkspaceId) {
        workspace = workspaces.find(workspace => workspace.id === bookingState.reassignWorkspaceId)
      } else if (selectedWorkspaceInSession) {
        workspace = workspaces.find(workspace => workspace.id === selectedWorkspaceInSession)
      }

      workspace = workspace || workspaces[0]
      if (workspace) {
        setWorkspace(workspace)
        setLoadedInitialWorkspace(true)
      } else if (!workspaces.length) {
        setView({ view: 'My Calendar' })
        setLoadedInitialWorkspace(true)
      }
    }
  }, [
    isLoadingWorkspaces,
    globalState.session.workspaces.selected,
    workspaces,
    bookingState.reassignWorkspaceId,
    bookingState.selectedWorkspace,
    loadedInitialWorkspace,
  ])

  useEffect(() => {
    /*
      Set additional info for guests and prospect or initial info if we have reportId.
    */
    if (!isLoadingProspect && !isLoadingReassign) {
      if (reassignData && !loadedReassignData) {
        // Error code can be a string
        if (reassignData.errorCode) {
          if (reassignData.errorCode.toString().includes('cancelled')) {
            navigate(routes.error.canceled)
          } else {
            navigate(routes.error.general)
          }
          return
        }

        setBookingState({
          reassignTemplateId: reassignData.templateId,
          reassignQueueId: reassignData.queueId,
          reassignWorkspaceId: reassignData.workspaceId,
          calendarSelections: [
            createSelectedEvent({
              start: new Date(reassignData.start),
              end: new Date(reassignData.end),
              isFromReassign: true,
            }),
          ],
          start: startOfWeek(reassignData.start),
          end: endOfWeek(reassignData.start),
          reassignBooker: reassignData.booker
            ? inviteeOrStringToGuest(reassignData.booker)
            : undefined,
          /*
            reassignData.booker can be undefined if FE is deployed before BE.
            We need to filter booker out so his availability won't be taken into account to book a meeting
          */
          guests: reassignData.guests
            .filter(guest => reassignData.booker && guest.email !== reassignData.booker.email)
            .map(inviteeOrStringToGuest),
        })
        setProspect({
          ...reassignData.prospect,
          mandatory: true,
        })

        setLoadedReassignData(true)
      } else {
        const prospectData = guestsInfo.find(guest => guest.email === bookingState.prospect?.email)
        if (prospectData) {
          setProspect(prospectData)
        }
        const guests = guestsInfo.filter(guest => guest.email !== bookingState.prospect?.email)
        // Due to the default value of guestsInfo with [], first useEffect could remove all guests from state
        if (guests.length === bookingState.guests.length) {
          setBookingState({ guests })
        }
      }
    }
  }, [isLoadingProspect, isLoadingReassign, guestsKeys, loadedReassignData])

  useEffect(() => {
    setBookingState({ isLoadingMainGuestCRMData })

    if (!hasProspect() || !mainGuestCRMData) {
      return
    }

    if (isLoadingMainGuestCRMData) {
      setBookingState({ isLoadingMainGuestCRMData })
      return
    }
    if (!mainGuestCRMData.total) {
      // Prospect is not in CRM
      setBookingState({
        isLoadingMainGuestCRMData: false,
        mainGuestDuplicateCRMRecords: mainGuestCRMData,
      })
      setProspect({
        ...mainGuestCRMData.prospect,
        inCrm: false,
        mandatory: true,
        fullName: mainGuestCRMData.prospect.fullName ?? mainGuestCRMData.whichName,
      })
      return
    }

    if (!mainGuestCRMData.prospect.email) {
      showSnackbar({
        type: 'error',
        title: `Email for ${
          mainGuestCRMData.prospect.fullName ?? mainGuestCRMData.whichName
        } not found. Please, make sure there is a registered email for this prospect.`,
      })
      return
    }

    setBookingState({
      isLoadingMainGuestCRMData: false,
      mainGuestDuplicateCRMRecords: mainGuestCRMData,
    })
    setProspect({
      ...mainGuestCRMData.prospect,
      inCrm: true,
      mandatory: true,
      fullName: mainGuestCRMData.prospect.fullName ?? bookingState.prospect?.fullName,
    })
  }, [mainGuestCRMData, isLoadingMainGuestCRMData])

  return (
    <>
      <SuspendedAccountBanner />
      <SuspenseWithFallback>
        <Routes>
          <Route path={routes.intermediary} element={<IntermediaryScreen />} />
          <Route path={routes.booker} element={<Booker />} />
          <Route path={routes.handoff} element={<Handoff />} />
          <Route path={routes.booked} element={<Booked />} />
          <Route path={routes.error.session} element={<SessionError />} />
          <Route path={routes.error.canceled} element={<CanceledMeeting />} />
          <Route path={routes.error.general} element={<GeneralError />} />
          <Route path={routes.duplicateRecords} element={<DuplicateRecords />} />
          <Route path='/' element={getRootRoute()} />
        </Routes>
      </SuspenseWithFallback>
    </>
  )
}

export const App = withProfiler(() => {
  return (
    <>
      <GlobalStyles />
      <SentryErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <ThemeProvider>
            <Main />
          </ThemeProvider>
        </QueryClientProvider>
      </SentryErrorBoundary>
    </>
  )
})

export const AppWithRouter = () => {
  return (
    <Router basename={process.env.REACT_APP_ROUTER_BASENAME}>
      <GlobalStateProvider>
        <BookingStateProvider>
          <CrmStateProvider>
            <SettingsStateProvider>
              <App />
            </SettingsStateProvider>
          </CrmStateProvider>
        </BookingStateProvider>
      </GlobalStateProvider>
    </Router>
  )
}
