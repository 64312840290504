import { CustomRoom } from '../../../types'

export const ALL_ROOMS: CustomRoom = {
  name: 'Any room',
  id: 'ALL_ROOMS',
  location: '',
  isAvailable: true,
  queues: [],
}

export const NO_ROOM: CustomRoom = {
  name: 'No room',
  id: 'NO_ROOM',
  location: '',
  isAvailable: true,
  queues: [],
}
