import React from 'react'
import { Flex } from '@chilipiper/design-system'
import { Loader } from '@chilipiper/design-system/src/new'

interface Props {
  width?: string
}

export function DefaultLoading({ width = '100%' }: Props) {
  return (
    <Flex
      h='100vh'
      width={width}
      alignItems='center'
      justifyContent='center'
      data-test-id='App-loading'
    >
      <Loader />
    </Flex>
  )
}
