export type StorageKeys =
  | 'DISPLAY_BUFFERS'
  | 'DISPLAY_BUFFERS_FOR_SUGGESTED_TIMES'
  | 'SIDEBAR_WIDTH'
  | 'DISPLAY_BOOKED_MEETINGS'
  | 'DISPLAY_WEEKENDS'

export function setStorage(key: StorageKeys, value: string) {
  localStorage.setItem(key, value)
}

export function getStorage(key: StorageKeys) {
  return localStorage.getItem(key)
}

export function getBooleanSetting(key: StorageKeys, defaultValue?: boolean) {
  const item = getStorage(key)
  if (item) {
    if (item === 'true') return true
    else return false
  }
  return defaultValue || false
}
