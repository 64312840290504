export const params = new URLSearchParams(window.location.search)

export const getQueryParam = function <T>(name: string, defaultValue?: T) {
  const param = params.get(name)?.replaceAll(' ', '+') || defaultValue
  if (param === 'undefined' || param === 'null') return undefined
  return param
}

export const getQueryParamBoolean = (name: string, defaultValue = false) => {
  const param = getQueryParam(name, defaultValue)
  if (param === 'false' || !param) return false
  return true
}
