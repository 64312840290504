import sortBy from 'lodash/sortBy'
import minBy from 'lodash/minBy'
import { QueueMember, QueueWithRules } from '../types'

function findFirstInMembers(members: QueueMember[]) {
  const onVacation = members.filter(member => member.onVacation)
  const accessibles = members.filter(
    member =>
      member.accessible &&
      member.order >= 0 &&
      !member.onVacation &&
      (member.workingHoursSet || member.workingHoursSet === undefined)
  )
  const first =
    accessibles.length === 0
      ? minBy(onVacation, member => member.order)
      : minBy(accessibles, member => member.order)
  return first
}

function findFirstOwner(members: QueueMember[], owners: string[]) {
  if (owners.length) {
    const memberOwners = members.filter(member => owners.some(o => o === member.id))
    const first = findFirstInMembers(memberOwners) || memberOwners[0]
    return first
  }

  return undefined
}

function findGroupAssignee(members: QueueMember[]) {
  return members.find(member => member.main)
}

export const findNextQueueMember = (queue: QueueWithRules, members: QueueMember[]) => {
  if (queue.algorithm === 'Group') return findGroupAssignee(members)
  const firstOwner = findFirstOwner(members, queue.owners)
  const first = findFirstInMembers(members)
  return firstOwner || first
}

const isMemberOnline = (member: QueueMember) =>
  member.available && member.accessible && !member.onVacation

export const findNextHandoffMember = (
  queue: QueueWithRules,
  members: QueueMember[],
  index: number
): QueueMember => {
  const owners = queue.owners
  const queueMembers = owners.length
    ? members.filter(member => owners.some(o => o === member.id))
    : members
  const sortedMembers = sortBy(queueMembers, member => member.order)
  const firstInQueue = findNextQueueMember(queue, sortedMembers) || sortedMembers[0]
  const afterFirst = sortedMembers.filter(
    member => member.order >= firstInQueue.order && isMemberOnline(member)
  )
  const firstAvailable =
    afterFirst.find(isMemberOnline) || sortedMembers.find(isMemberOnline) || firstInQueue
  if (index > 0) {
    return findNextHandoffMember(
      queue,
      members.filter(m => m.id !== firstAvailable.id),
      index - 1
    )
  }
  return firstAvailable
}
