import React, { createContext } from 'react'
import useSetState from 'react-use/lib/useSetState'
import { endOfMonth, endOfWeek, getMonth, startOfMonth, startOfWeek } from 'date-fns'
import { View } from 'react-big-calendar'
import { useBookingState, useGlobalState } from '../../hooks/context'
import { getBufferToggleState } from '../../components/calendar/helpers'
import { getBooleanSetting } from '../../utils/storage'
import { CalendarView } from '../../types'

interface Props {
  children: React.ReactNode
  initialState?: Partial<State>
}

export interface State {
  showBuffers: boolean
  showBookedMeetings: boolean
  showWeekends: boolean
  view: View
  weekViewStart: Date
}

interface SettingsStateValue {
  settingsState: State
  setSettingsState: (state: Partial<State>) => void
  onViewChange: (view: CalendarView, start?: Date) => void
}

export const SettingsContext = createContext({} as SettingsStateValue)

export const SettingsStateProvider: React.FC<Props> = ({ children, initialState }) => {
  const { globalState } = useGlobalState()
  const { bookingState, setBookingState } = useBookingState()

  const [state, setState] = useSetState<State>({
    showBuffers: getBufferToggleState(!!globalState.isExtension, bookingState.selectedTemplate),
    showWeekends: getBooleanSetting('DISPLAY_WEEKENDS', false),
    showBookedMeetings: getBooleanSetting('DISPLAY_BOOKED_MEETINGS', true),
    view: getBooleanSetting('DISPLAY_WEEKENDS', false) ? 'week' : 'work_week',
    weekViewStart: bookingState.start,
    ...initialState,
  })

  const onViewChange = React.useCallback(
    (view: CalendarView, start?: Date) => {
      const newStart = start || bookingState.start
      if (view === 'month') {
        setBookingState({
          start: startOfMonth(newStart),
          end: endOfMonth(newStart),
        })
        setState({
          view,
          weekViewStart: newStart,
        })
      } else {
        const selectedView = state.showWeekends ? 'week' : 'work_week'
        // Use case when week view has days from 2 months
        const isDifferentMonth =
          getMonth(newStart) !== getMonth(state.weekViewStart) &&
          getMonth(newStart) !== getMonth(endOfWeek(state.weekViewStart))
        if (isDifferentMonth) {
          setBookingState({
            start: startOfWeek(newStart),
            end: endOfWeek(newStart),
          })
          setState({
            view: selectedView,
            weekViewStart: startOfWeek(newStart),
          })
        } else {
          const newWeekViewStart = start ? startOfWeek(start) : state.weekViewStart
          setBookingState({
            start: newWeekViewStart,
            end: endOfWeek(newWeekViewStart),
          })
          setState({
            view: selectedView,
            weekViewStart: newWeekViewStart,
          })
        }
      }
    },
    [bookingState.start, state.view, state.showWeekends]
  )

  const contextValue = {
    settingsState: state,
    setSettingsState: setState,
    onViewChange,
  }

  return <SettingsContext.Provider value={contextValue}>{children}</SettingsContext.Provider>
}
