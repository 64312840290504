import { Invitee, Template, Prospect } from '@chilipiper/api-type-def'
import { getClientTimezone } from '@chilipiper/date-time'
import { add } from 'date-fns'
import { State as BookingState } from '../../context/booking'
import { CalendarEvent, FixedBookRequest, Guest, QueueMember, TemplateValues } from '../../types'
import { SIDEBAR_WIDTH, SIDEBAR_WIDTH_EXPANDED } from '../../utils/constants'
import { getQueryParam } from '../../utils/query-param'
import { getRoomId } from '../../utils/reports'
import { getStorage } from '../../utils/storage'

const clientTimezone = getClientTimezone()

interface RequestBookingData extends Partial<FixedBookRequest> {
  bookingState: BookingState
  templateValues: TemplateValues
  event: CalendarEvent
  selectedTemplate: Template
  invitees: Invitee[]
  guests: string[]
  relateTo?: string
  assigneesInfo?: QueueMember[]
  reportId?: string
}

interface ButtonDisableState {
  isLoading: boolean
  isBooking: boolean
  isParsingTemplate: boolean
  hasSelections: boolean
  hasProspect: boolean
  hasAssignee: boolean
}

export const getButtonDisableState = ({
  isLoading,
  isBooking,
  isParsingTemplate,
  hasSelections,
  hasProspect,
  hasAssignee,
}: ButtonDisableState) => {
  const isDisabled =
    isLoading || isBooking || isParsingTemplate || !hasSelections || !hasProspect || !hasAssignee
  let text = ''
  if (isLoading) {
    text = 'Loading...'
  } else if (isBooking) {
    text = 'Booking meeting...'
  } else if (isParsingTemplate) {
    text = 'Loading template details...'
  } else if (!hasSelections) {
    text = 'Pick a slot to continue'
  } else if (!hasProspect) {
    text = 'Add a guest to continue'
  } else if (!hasAssignee) {
    text = 'Select an assignee to continue'
  }

  return {
    disabled: isDisabled,
    tooltip: text,
  }
}

export const getBookingData = ({
  templateValues,
  selectedTemplate,
  event,
  invitees,
  bookingState,
  guests,
  ...others
}: RequestBookingData): FixedBookRequest => {
  const prospect = bookingState.prospect as Guest
  const isBookingOnSelf = bookingState.isBookingOnSelf
  const roomId = isBookingOnSelf ? undefined : getRoomId(bookingState.selectedRoom)
  const start = event.start.valueOf()
  const end = event.end.valueOf()
  const queueId = isBookingOnSelf ? undefined : bookingState.selectedQueue?.id
  // in cases where user has no workspaces to select or free users
  const workspaceId = bookingState.selectedWorkspace?.id ?? selectedTemplate.workspaceId
  return {
    ...templateValues,
    assigneeEmail: bookingState.assignee?.email ?? '',
    startDate: start as number,
    endDate: end as number,
    id: prospect.id || null,
    timezone: bookingState.timezone,
    assigneesInfo: [],
    sequentials: [],
    invitees: invitees,
    isLead: bookingState.prospect?.isLead || false,
    inviteProspect: !selectedTemplate.withoutProspect,
    template: selectedTemplate.id,
    assigneeId: bookingState.assignee?.id as string,
    source: 'Booker',
    orderPositions: bookingState.nextAssignee?.id
      ? {
          firstInQueueId: bookingState.nextAssignee.id,
        }
      : undefined,
    prospect: prospect.email,
    multipleSelection: [],
    locale: bookingState.locale,
    /*
      The data on BookerInfo here is returned by the Reports endpoint, which we use to get Events calendar data.
      This needs to contain the same values from the general request
    */
    bookerInfo: {
      assigneesIds: others.assigneesInfo?.map(assignee => assignee.id) || [],
      assigneesGroupIds: [],
      template: selectedTemplate.id,
      step: bookingState.step,
      start: start,
      end: end,
      guests: [prospect.email].concat(guests),
      withAvailableDays: false,
      withBuffers: false,
      roomsIds: roomId ? [roomId] : [],
      queueId: queueId,
    },
    possibleMeetingLocations: bookingState.meetingLocations,
    workspaceId: isBookingOnSelf ? undefined : workspaceId,
    queueId: queueId,
    roomId,
    ...others,
  }
}

interface HandoffRequestData {
  assigneesInfo: QueueMember[]
  bookingState: BookingState
}

export const getHandoffBookingData = ({ bookingState, assigneesInfo }: HandoffRequestData) => {
  const prospect = bookingState.prospect as Guest
  const selectedTemplate = bookingState.selectedTemplate
  const selectedQueue = bookingState.selectedQueue
  const assigneeId = bookingState.assignee?.id ?? ''
  const objectId = getQueryParam('objectId')
  const start = new Date().valueOf()
  const end = add(new Date(), { minutes: bookingState.selectedTemplate?.duration ?? 30 }).valueOf()
  const guests = bookingState.guests.map(guest => guest.email)
  return {
    startDate: start,
    endDate: end,
    title: `[Hot Handoff] ${selectedTemplate?.title ? selectedTemplate.title : 'Demo Meeting'}`,
    location: selectedTemplate?.location ?? '',
    description: selectedTemplate?.description ?? '',
    assigneeId,
    prospect: bookingState.prospect?.email || null,
    id: prospect.id || null,
    isLead: prospect.isLead === true,
    queueId: selectedQueue?.id ?? '',
    timezone: clientTimezone,
    inviteProspect: false,
    source: 'Handoff',
    guests,
    relateTo: objectId,
    template: selectedTemplate?.id,
    multipleSelection: [],
    assigneesInfo: assigneesInfo,
    bookerInfo: {
      assigneesIds: [assigneeId],
      template: selectedTemplate?.id,
      step: bookingState.step,
      start: start,
      end: end,
      guests: [prospect.email].concat(guests),
      withAvailableDays: false,
      withBuffers: false,
      roomsIds: [],
      queueId: selectedQueue?.id,
    },
  }
}
interface BuildParseData {
  template?: Template
  rawProspect?: Guest | Prospect
  accountId?: string
  assigneeId: string
  prospectId?: string
  eventId?: string
  roomId?: string
  caseId?: string
  opportunityId?: string
}

export const buildParseData = ({
  template,
  accountId,
  assigneeId,
  prospectId,
  eventId,
  roomId,
  caseId,
  rawProspect,
  opportunityId,
}: BuildParseData) => {
  return ['title', 'location', 'description'].map(value => {
    return {
      clearEmpty: true,
      title: value,
      accountId,
      assigneeId,
      template: template?.[value as keyof Template] as string,
      prospectId,
      rawProspect,
      eventId,
      roomId,
      caseId,
      opportunityId,
    }
  })
}

export const getInitialSidebarWidth = () => getStorage('SIDEBAR_WIDTH') || `${SIDEBAR_WIDTH}px`

export const getSidebarExpandedState = (currentWidthStr: string) => {
  if (!currentWidthStr) return false
  const currentWidth = +currentWidthStr.replace('px', '')
  return currentWidth >= SIDEBAR_WIDTH_EXPANDED
}
