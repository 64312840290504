import { BookerCalendarEvent, Report } from '@chilipiper/api-type-def'
import { CustomRoom, CustomRoomIds } from '../types'

export const reportToBookerCalendar = (report: Report): BookerCalendarEvent => {
  return {
    assigneeIds: report.bookRequest.bookerInfo.assigneesIds,
    attended: true,
    attendees: [],
    cappedByTemplateAssigneeIds: [],
    fromBooker: false,
    id: report.id,
    isBusy: true,
    isFullDay: false,
    isGuest: false,
    isPrivate: false,
    recurrent: false,
    roomsIds: report.bookRequest.bookerInfo.roomsIds || [],
    sequentials: [],
    end: new Date(report.bookRequest.endDate).valueOf(),
    start: new Date(report.bookRequest.startDate).valueOf(),
    title: report.bookRequest.title,
  }
}

export const isCustomRoom = (roomId?: string | CustomRoomIds) => {
  if (!roomId) return true

  const customRoomId = roomId as CustomRoomIds
  if (customRoomId === 'ALL_ROOMS' || customRoomId === 'NO_ROOM') return true

  return false
}

export const getRoomId = (room?: CustomRoom) => {
  if (!room || (room.id as CustomRoomIds) === 'NO_ROOM') return undefined

  if ((room.id as CustomRoomIds) === 'ALL_ROOMS') {
    return room.anyRoomSelected?.id
  }

  return room.id
}

export const isAnyRoomSelected = (roomId?: string) => {
  if (!roomId) return false
  return (roomId as CustomRoomIds) === 'ALL_ROOMS'
}

export const isNoRoom = (roomId?: string) => {
  if (!roomId) return true
  return (roomId as CustomRoomIds) === 'NO_ROOM'
}

export const filterByRoomAndAssignee =
  (room?: CustomRoom, assigneeId?: string) => (report: BookerCalendarEvent) => {
    const roomId = getRoomId(room)
    const matchesAssignee = report.assigneeIds.includes(assigneeId as string)
    const isAnyRoom = isAnyRoomSelected(room?.id)

    if (isNoRoom(room?.id)) {
      return matchesAssignee
    }

    const matchesRoom = isAnyRoom ? true : report.roomsIds.includes(roomId || '')

    return matchesAssignee || matchesRoom
  }
