import {
  add,
  areIntervalsOverlapping,
  eachWeekendOfInterval,
  endOfDay,
  getDay,
  isBefore,
} from 'date-fns'
import uniq from 'lodash/uniq'
import sortBy from 'lodash/sortBy'
import {
  BookerCalendarEvent,
  Event,
  GlobalSettings,
  Queue,
  Template,
} from '@chilipiper/api-type-def'
import { SnackbarShow } from '@chilipiper/design-system/src/old/snackbar/types'
import { Assignee, CalendarEvent, CustomRoom, QueueMember, QueueWithRules } from '../../types'
import { getPayloadStep } from '../../utils/calendar'
import { getStorage, setStorage, StorageKeys } from '../../utils/storage'
import { hasSameTime, isSameOrAfter, isSameOrBefore } from '../../utils/time'
import { convertTimezone, convertTimezoneToLocal } from '../../utils/timezone'
import { getRoomId } from '../../utils/reports'
import { State as BookingState } from '../../context/booking'
import { findNextQueueMember } from '../../utils/queue-member-finder'

export const isEventOnWeekends = (event: Event) => {
  if (!event.start || !event.end) {
    return false
  }

  return (
    eachWeekendOfInterval({
      start: event.start,
      end: event.end,
    }).length > 0
  )
}

export const getCalendarStep = (currentStep: number) => {
  if (currentStep === 5 || currentStep === 10) return 5
  return 15
}

export const getHoverIndicatorHeight = (currentStep: number) => {
  const minBlockTime = getPayloadStep(currentStep)
  const hoverIndicatorHeight = Math.round((currentStep / minBlockTime) * 100)
  return `${hoverIndicatorHeight}%`
}

export const getTimeGutterFormat = (currentStep: number) => {
  if (currentStep === 5 || currentStep === 10) {
    return 'h:mm a'
  }
  return 'h a'
}

interface MapEventClassesParam {
  event: CalendarEvent
  assigneeId?: string
  isEventWorkspace?: boolean
  queueMembers: QueueMember[]
  loading?: boolean
}

export const mapEventClasses = ({
  event,
  assigneeId = '',
  queueMembers,
  loading,
}: MapEventClassesParam) => {
  const isAvailabilityForAll = !assigneeId
  const classNames = ['chili-calendar-slot']

  if (loading) return { className: 'chili-calendar-slot chili-loading-slot' }

  if (!isAvailabilityForAll && event.cappedByTemplateAssigneeIds?.length > 0) {
    return { className: 'chili-calendar-slot chili-slot-busy chili-slot-max-reached' }
  }

  if (isAvailabilityForAll) {
    classNames.push('chili-slot-availability-for-all')
  }

  const members = isAvailabilityForAll
    ? queueMembers.filter(member => member.accessible || member.accessible === undefined)
    : queueMembers

  const membersIds = members.map(member => member.id)
  const isMemberOfQueue = (id: string) => membersIds.includes(id)

  const busyIds = event.busyAssigneeIds.filter(isMemberOfQueue)

  const hasQueueMembers = members.length > 0
  const hasOneFreeAssignee = members.length > 1 && members.length - busyIds.length === 1
  const allMembersAreBusy = busyIds.length >= members.length
  const isEventFromAssignee = event.assigneeId === assigneeId

  if (!event.reserved) {
    if (event.forSelection) {
      if (event.isMeetingBuffer) {
        classNames.push('chili-selected-slot-buffer')
      }
      classNames.push('chili-selected-slot')
    } else if (event.isBusy && (event.title || isEventFromAssignee)) {
      classNames.push('chili-calendar-event')
    } else if (hasQueueMembers && allMembersAreBusy) {
      classNames.push('chili-slot-busy')
    } else if (isAvailabilityForAll && hasOneFreeAssignee) {
      classNames.push('chili-slot-partial-busy')
    } else if (allMembersAreBusy || event.isBusy) {
      classNames.push('chili-slot-busy')
    } else if (isAvailabilityForAll) {
      classNames.push('chili-free-to-select-cell')
    } else {
      classNames.push('chili-calendar-event')
    }

    if (event.isFullWidth) {
      classNames.push('chili-full-width-slot')
    }

    if (event.isFullDay) {
      classNames.push('chili-event-full-day')
      if (event.assigneeIds.length === 1 && event.assigneeIds[0] === assigneeId) {
        classNames.push('chili-slot-from-assignee')
      }
    }

    if (event.hasEventOnSameStart && !isAvailabilityForAll) {
      classNames.push('chili-multiple-events')
    }

    if (event.isBlockedForAnyRoom) {
      classNames.push('chili-slot-blocked')
    }
  } else {
    if (hasQueueMembers && allMembersAreBusy) {
      classNames.push('chili-slot-busy')
    } else if (hasOneFreeAssignee) {
      classNames.push('chili-slot-partial-busy')
    } else {
      classNames.push('chili-free-to-select-cell')
    }
  }

  if (isBefore(event.start, new Date()) && !isAvailabilityForAll) {
    classNames.push('chili-past-event')
  }

  return {
    className: classNames.join(' '),
  }
}

export const scrollDown = (retries = 0): NodeJS.Timeout | undefined => {
  const indicator = document.querySelector('.rbc-current-time-indicator')
  const events = document.querySelectorAll('.chili-calendar-slot')
  const selectedSlot = document.querySelector('.chili-selected-slot')
  if (selectedSlot) {
    selectedSlot.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  } else if (indicator) {
    indicator.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  } else if (events.length) {
    // Get the event closest to the top and scroll to it
    const elements = Array.from(events).filter(
      event => !event.classList.contains('chili-event-full-day')
    ) as HTMLElement[]
    const offsets = elements.map(slot => slot.offsetTop).filter(offset => offset !== 0)
    const minOffset = Math.min(...offsets)
    const index = elements.findIndex(element => element.offsetTop === minOffset)
    if (index >= 0) {
      elements[index].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  } else if (retries <= 10) {
    return setTimeout(() => scrollDown(retries + 1), 250)
  }
}

export const scrollToAssignee = (assigneeId?: string) => {
  if (assigneeId) {
    const element = document.querySelector(`[data-test-id="member-${assigneeId}"]`)
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }
}

/*
  The correct way to handle availability for all would be to make the calendar step = slot step.
  However this doesn't work since BE does not check correctly who is available at higher durations. To fix that, we use a fixed step of 30 durations above 30, and 15 for durations below it to increase precision. The idea is to check, for each event, if the assignees available on it are really available by checking the next events up to the duration selected.
*/

const removePastSlots = (events: CalendarEvent[], timezone: string): CalendarEvent[] => {
  return events.filter(
    event => !isBefore(event.start, convertTimezoneToLocal(new Date(), timezone))
  )
}

export const aggregateEventsForAvailabilityForAll = (
  events: CalendarEvent[],
  duration: number,
  timezone: string
) => {
  const durationDivisor = duration < 30 || [45, 55].includes(duration) ? 15 : 30
  const sortedEvents = sortBy(removePastSlots(events, timezone), event => event.start.valueOf())
  // We need to merge assigneeIds from slots with same time
  const mergedEvents = sortedEvents.reduce((acc, event) => {
    const hasProcessedEvent = acc.some(processedEvent => hasSameTime(event, processedEvent))
    if (hasProcessedEvent) {
      return acc
    }
    const eventsAtSameTime = sortedEvents.filter(event2 => hasSameTime(event, event2))
    const assigneeIds = eventsAtSameTime.flatMap(e => e.assigneeIds)
    const cappedByTemplateAssigneeIds = eventsAtSameTime.flatMap(e => e.cappedByTemplateAssigneeIds)
    acc.push({
      ...event,
      assigneeIds: uniq(assigneeIds),
      cappedByTemplateAssigneeIds: uniq(cappedByTemplateAssigneeIds),
      busyAssigneeIds: uniq(assigneeIds.concat(cappedByTemplateAssigneeIds)),
    })
    return acc
  }, [] as CalendarEvent[])
  return mergedEvents.map(event => {
    // If event starts or goes past midnight it is not added in the calendar, but goes to an "all day" section we are hiding
    const isEndOfDay =
      add(event.start, { minutes: durationDivisor }).valueOf() ===
      endOfDay(event.start).valueOf() + 1
    /*
      We need to merge assigneeIds from slots which has a conflict of times between
      slot start time and the start + the meeting duration (it's not the same as the end of event as FE requests for slots with step of 15 or 30 for availability for all)
    */
    const allEvents = mergedEvents.filter(event2 => {
      return areIntervalsOverlapping(
        {
          start: event.start,
          end: add(event.start, { minutes: duration }),
        },
        {
          start: event2.start,
          end: event2.end,
        }
      )
    })
    const assigneeIds = allEvents.flatMap(e => e.assigneeIds)
    const cappedByTemplateAssigneeIds = allEvents.flatMap(e => e.cappedByTemplateAssigneeIds)
    return {
      ...event,
      assigneeIds: uniq(assigneeIds),
      cappedByTemplateAssigneeIds: uniq(cappedByTemplateAssigneeIds),
      busyAssigneeIds: uniq(assigneeIds.concat(cappedByTemplateAssigneeIds)),
      end: isEndOfDay ? new Date(event.end.getTime() - 1) : event.end,
    }
  })
}

export const endAccessor = (event: CalendarEvent, step: number) => {
  const newEnd = add(event.end, { minutes: step })
  if (getDay(newEnd) !== getDay(event.end)) {
    return new Date(event.end.getTime() - 1)
  }
  return event.end
}

export interface TempEvent extends Partial<CalendarEvent> {
  start: Date
  end: Date
}

export const createSelectedEvent = (slot: TempEvent): CalendarEvent => {
  return {
    id: slot.start.getTime().toString(),
    assigneeIds: [],
    attended: false,
    attendees: [],
    cappedByTemplateAssigneeIds: [],
    fromBooker: false,
    isBusy: false,
    isFullDay: false,
    isGuest: false,
    isPrivate: false,
    recurrent: false,
    roomsIds: [],
    sequentials: [],
    busyAssigneeIds: [],
    forSelection: true,
    ...slot,
  }
}

export const filterIntervalsOverlapping = function <T extends CalendarEvent | BookerCalendarEvent>(
  slots: T[],
  event: CalendarEvent,
  timezone?: string
) {
  return slots.filter(slot => {
    return areIntervalsOverlapping(
      {
        start: timezone ? convertTimezoneToLocal(event.start, timezone) : event.start,
        end: timezone ? convertTimezoneToLocal(event.end, timezone) : event.end,
      },
      {
        start: slot.start,
        end: slot.end,
      }
    )
  })
}

export const isEventLengthInsideSlots = (
  slotsOverlapping: BookerCalendarEvent[],
  event: CalendarEvent
) => {
  if (slotsOverlapping.length === 0) return false

  const slotStart = slotsOverlapping[0].start
  const slotEnd = slotsOverlapping[slotsOverlapping.length - 1].end
  return isSameOrAfter(event.start, slotStart) && isSameOrBefore(event.end, slotEnd)
}

export const showOutsideWorkingHoursSnackbar = (
  showSnackbar: (snackbar: SnackbarShow) => void,
  count = 1
) => {
  showSnackbar({
    type: 'warning',
    duration: 3000,
    title:
      count > 1
        ? `There are ${count} selections outside working hours`
        : 'The selected time is outside of working hours',
  })
}

export const showBusySelectionSnackbar = (
  showSnackbar: (snackbar: SnackbarShow) => void,
  count = 1
) => {
  showSnackbar({
    type: 'warning',
    title:
      count > 1
        ? `${count} selections were removed because you already meeting on those slots`
        : 'There is a meeting already booked on this slot',
  })
}

export const showHostLimitSnackbar = (showSnackbar: (snackbar: SnackbarShow) => void) => {
  showSnackbar({
    type: 'warning',
    title: 'Number of meetings for this meeting type has been reached on this date',
  })
}

export const isOutsideWorkingHours = (
  slotsOverlapping: BookerCalendarEvent[],
  eventsOverlapping: BookerCalendarEvent[] | CalendarEvent[],
  event: CalendarEvent
) => {
  return (
    (slotsOverlapping.length === 0 && eventsOverlapping.length === 0) ||
    !isEventLengthInsideSlots(slotsOverlapping, event)
  )
}

interface BufferParams {
  event: CalendarEvent
  template?: Template
  assigneeId?: string
}

export const createBufferForSelectedEvent = ({
  event,
  template,
  assigneeId,
}: BufferParams): CalendarEvent[] => {
  // For meeting buffers we dont want them to overlap slot
  const MIN_IN_MILLISECONDS = 59000
  const events = [] as CalendarEvent[]
  const before = template?.buffers?.before ?? 0
  const after = template?.buffers?.after ?? 0
  if (!assigneeId || (before === 0 && after === 0)) {
    return []
  }

  if (before > 0) {
    events.push({
      ...createSelectedEvent({
        start: new Date(event.start.getTime() - before * MIN_IN_MILLISECONDS),
        end: new Date(event.start.getTime() - 60000),
        isFullWidth: event.isFullWidth,
      }),
      title: `${before}m Buffer`,
      isMeetingBuffer: true,
      id: event.id,
    })
  }

  if (after > 0) {
    events.push({
      ...createSelectedEvent({
        start: new Date(event.end),
        end: new Date(event.end.getTime() + after * MIN_IN_MILLISECONDS),
        isFullWidth: event.isFullWidth,
      }),
      title: `${after}m Buffer`,
      isMeetingBuffer: true,
      id: event.id,
    })
  }

  return events
}

export const getBufferToggleState = (
  isExtension: boolean,
  selectedTemplate?: Template
): boolean => {
  const storageKey: StorageKeys = isExtension
    ? 'DISPLAY_BUFFERS_FOR_SUGGESTED_TIMES'
    : 'DISPLAY_BUFFERS'
  const storedState = getStorage(storageKey)

  if (storedState) {
    return JSON.parse(storedState)
  }

  const state = !!selectedTemplate?.createBuffersInIB
  return state
}

export const setBufferToggleState = (isExtension: boolean, state: boolean) => {
  const storageKey: StorageKeys = isExtension
    ? 'DISPLAY_BUFFERS_FOR_SUGGESTED_TIMES'
    : 'DISPLAY_BUFFERS'
  setStorage(storageKey, `${state}`)
}

export const getAvailableAssigneeIds = (event: CalendarEvent, members: QueueMember[]) => {
  if (event.busyAssigneeIds.length >= members.length) {
    return []
  }
  return members.filter(member => {
    return (
      !event.busyAssigneeIds.includes(member.id) &&
      (member.accessible || member.accessible === undefined)
    )
  })
}

interface SelectionBusyParams<T> {
  eventsOverlapping: T[]
  queue?: Queue
  preferences?: GlobalSettings
  userId?: string
  assignee?: Assignee
  isAvailabilityForAll?: boolean
  isBookingOnSelf?: boolean
  isFromReassign?: boolean
}

export const isSelectionBusy = function <T extends BookerCalendarEvent | CalendarEvent>({
  eventsOverlapping,
  queue,
  preferences,
  userId,
  isAvailabilityForAll,
  isBookingOnSelf,
  isFromReassign,
  assignee,
}: SelectionBusyParams<T>) {
  const isBookingOnBookerCalendar =
    queue?.ownership === 'Booker' && userId === queue?.onlineSettings?.defaultBooker

  if (isBookingOnSelf || isAvailabilityForAll) return false

  if (isFromReassign && isBookingOnBookerCalendar) {
    return eventsOverlapping.some(
      event => event.isBusy && event.assigneeIds.includes(assignee?.id ?? '')
    )
  }

  if (preferences && !preferences.allowBusy) {
    if (eventsOverlapping.some(event => event.isBusy && event.assigneeIds.length > 0)) {
      return true
    }
  }
  return false
}

export const handleHostBookingLimit = (
  showSnackbar: (snackbar: SnackbarShow) => void,
  slot: CalendarEvent,
  isBookingOnSelf: boolean,
  assignee?: Assignee
) => {
  if (slot.cappedByTemplateAssigneeIds?.length > 0 && assignee) {
    showHostLimitSnackbar(showSnackbar)
    if (!isBookingOnSelf) {
      return true
    }
  }
  return false
}

export interface OnSelectSlotOptions {
  fromMeetingClick?: boolean
}

export const getRelatedEvents = (
  showBuffers: boolean,
  timezone: string,
  step: number,
  calendarEvents: CalendarEvent[],
  slots: BookerCalendarEvent[],
  slot: CalendarEvent,
  template?: Template,
  assignee?: Assignee,
  options?: OnSelectSlotOptions
) => {
  const endDate = new Date(slot.start)
  endDate.setMinutes(endDate.getMinutes() + step)
  const event = createSelectedEvent({
    start: convertTimezone(slot.start, timezone),
    end: convertTimezone(endDate, timezone),
    isFullWidth: !options?.fromMeetingClick,
  })
  const eventsOverlapping = filterIntervalsOverlapping(calendarEvents, event, timezone)

  const bufferEvents = showBuffers
    ? createBufferForSelectedEvent({
        event,
        template,
        assigneeId: assignee?.id,
      })
    : []

  const assigneeEvents = calendarEvents.filter(event =>
    event.assigneeIds.includes(assignee?.id ?? '')
  )

  const buffersOverlappingSelection = bufferEvents.some(
    bufferEvent => filterIntervalsOverlapping(assigneeEvents, bufferEvent, timezone).length > 0
  )
  const slotsOverlapping = filterIntervalsOverlapping(slots, event)

  return { slotsOverlapping, buffersOverlappingSelection, bufferEvents, eventsOverlapping, event }
}

export const handleEventWorkspace = (
  isEventWorkspace: boolean,
  isAnyRoom: boolean,
  timezone: string,
  event: CalendarEvent,
  calendarEvents: CalendarEvent[],
  showSnackbar: (snackbar: SnackbarShow) => void,
  selectedRoom?: CustomRoom
) => {
  if (!isEventWorkspace) {
    return false
  }
  const calendarEventsOverlappingSelection = calendarEvents.filter(slot => {
    if (slot.reserved || slot.forSelection) return false
    return areIntervalsOverlapping(
      {
        start: convertTimezone(event.start, timezone),
        end: convertTimezone(event.end, timezone),
      },
      {
        start: slot.start,
        end: slot.end,
      }
    )
  })
  /*
    On Event workspaces, we need to block the user from booking in the same slot twice if they have the same room.
    On "Any room", we need to block booking on a slot that has all rooms booked on it already.
  */
  const blockedSlot = calendarEventsOverlappingSelection.find(slot => slot.isBlockedForAnyRoom)

  // If "Any room" is selected and there is any overlapping blocked slot, don't allow selection
  if (blockedSlot !== undefined) return

  /*
    Else, if there is any slot that has a booked meeting with the same selected room, also don't allow selection.
    If Any Room is selected we skip this check since the availability endpoint takes care of checking
  */
  const selectedRoomId = !isAnyRoom && getRoomId(selectedRoom)
  if (
    selectedRoomId &&
    calendarEventsOverlappingSelection.find(slot => slot.roomsIds.includes(selectedRoomId))
  ) {
    showSnackbar({
      type: 'warning',
      title: 'Selected room is busy on this slot',
    })
    return true
  }
}

export const handleAvailabilityForAllSelection = (
  slot: CalendarEvent,
  freeSlots: CalendarEvent[],
  queueMembers: QueueMember[],
  setBookingState: (state: Partial<Omit<BookingState, 'prospect'>>) => void,
  showSnackbar: (snackbar: SnackbarShow) => void,
  selectedQueue?: QueueWithRules,
  isAvailabilityForAll?: boolean
) => {
  if (!(isAvailabilityForAll && selectedQueue)) {
    return false
  }
  if (!slot.busyAssigneeIds || slot.busyAssigneeIds.length >= queueMembers.length) {
    showSnackbar({ type: 'warning', title: 'This slot is busy' })
    return true
  }
  // In availability for all, slot here can be an event, which won't have the properties we need to check that are on the slot of the same time
  const notMappedSlot = freeSlots.find(
    freeSlot => freeSlot.start.valueOf() === slot.start.valueOf()
  )

  // Select next available member if clicking from Availability for all selected
  const isEvent = !!slot.assigneeIds
  const freeMembers = isEvent
    ? queueMembers.filter(member => {
        return !slot.busyAssigneeIds.includes(member.id)
      })
    : queueMembers

  const nextMember = findNextQueueMember(selectedQueue, freeMembers)
  if (nextMember) {
    setBookingState({ assignee: nextMember, showAvailabilityForAll: false })
    scrollToAssignee(nextMember.id)
    return false
  }
  if (isEvent) {
    const freeMemberWithBookingLimit = findNextQueueMember(
      selectedQueue,
      queueMembers.filter(member => {
        return !slot.assigneeIds.includes(member.id)
      })
    )
    if (
      notMappedSlot?.cappedByTemplateAssigneeIds?.includes(freeMemberWithBookingLimit?.id ?? '')
    ) {
      showSnackbar({
        type: 'warning',
        title: `Number of meetings for this meeting type has been reached on this date for ${freeMemberWithBookingLimit?.name}`,
        duration: 5000,
      })
    }
  }
  return true
}

export const getFirstAvailableSlotInColumn = (date: Date): HTMLElement => {
  const timeColumn = document.querySelector(
    `.rbc-time-column:nth-of-type(${date.getDay()}) + div:not(.rbc-time-gutter)`
  )

  return timeColumn?.querySelector('.chili-free-to-select-cell .chili-slot-event') as HTMLElement
}

export const getNextDayInHeader = (date: Date): HTMLElement => {
  const nextDay = add(date, { days: 1 })
  const nextDayInHeader = Array.from(document.querySelectorAll(`.chili-header-day-number`))
    .find(element => Number(element.textContent) === nextDay.getDate())
    ?.closest('.chili-header-container') as HTMLElement
  if (!nextDayInHeader) {
    const rightSidebar = document.querySelector('[data-test-id=RightSidebar]')
    return rightSidebar?.querySelector('input, button, select, textarea, a[href]') as HTMLElement
  }

  return nextDayInHeader
}
