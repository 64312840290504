import React from 'react'
import { WorkspaceFeatureType } from '@chilipiper/models'
import { SvgEventsGradient } from '@chilipiper/icons/src/apps/EventsGradient'
import { Icon, Box, Flex, Text } from '@chilipiper/design-system'
import sortBy from 'lodash/sortBy'
import { StyledWorkspaceName } from './styles'
import { WorkspaceWithRole } from '../types'
import { usePersonalSettings } from '../hooks/query'
import { useBookingState } from '../hooks/context'

export const isEventWorkspace = (workspace?: WorkspaceWithRole) => {
  return workspace?.type === 'Events'
}

export const getWorkspaceTypeEmoji = (workspace: WorkspaceWithRole) => {
  if (isEventWorkspace(workspace)) {
    return <Icon icon={SvgEventsGradient} />
  }
  switch (workspace.productTypes.meetingsType) {
    case WorkspaceFeatureType.Mild:
      return (
        <span title='Spicy workspace' role='img' aria-label='Spicy workspace'>
          🌶
        </span>
      )
    case WorkspaceFeatureType.Free:
      return (
        <span title='Free workspace' role='img' aria-label='Free workspace'>
          🌱
        </span>
      )
    case WorkspaceFeatureType.Hot:
    default:
      return (
        <span title='Hot workspace' role='img' aria-label='Hot workspace'>
          🔥
        </span>
      )
  }
}

export const mapWorkspacesForSelect = (workspaces: WorkspaceWithRole[]) => {
  const sorted = sortBy(workspaces, w => w.name)
  return sorted.map(workspace => {
    const category =
      workspace.type === 'Events'
        ? 'Events'
        : !workspace.productTypes?.meetingsType
        ? 'Hot'
        : workspace.productTypes.meetingsType
    return {
      ...workspaces,
      category,
      label: workspace.name,
      value: workspace.id,
      labelNode: (
        <Text fontWeight={500} title={`Select ${workspace.name}`}>
          {workspace.name}
        </Text>
      ),
      renderAsSelected: () => (
        <Text fontWeight={500} lineHeight='115%'>
          <Flex alignItems='center'>
            <Box mr={2} aria-hidden='true'>
              {getWorkspaceTypeEmoji(workspace)}
            </Box>
            <StyledWorkspaceName title={workspace.name}>{workspace.name}</StyledWorkspaceName>
          </Flex>
        </Text>
      ),
    }
  })
}

export const useWorkspaceRole = () => {
  const { bookingState } = useBookingState()
  const { data: personalSettings } = usePersonalSettings()

  const role = personalSettings?.roles.find(
    role => role.workspaceId === bookingState.selectedWorkspace?.id
  )

  return role?.role || 'Booker'
}
