import { CreateContactPost, CreateLeadPost } from '@chilipiper/api-type-def'
import { crmEntity } from '@chilipiper/service/src/service'
import React, { createContext, useCallback } from 'react'
import useSetState from 'react-use/lib/useSetState'
import { CrmContextValidations, Guest, GuestValidation, GuestValidations } from '../../types'

interface Props {
  children: React.ReactNode
}

interface State {
  addToCrm?: boolean
  entityType: 'Contact' | 'Lead'
  crmCheckedGuests: Guest[]
  validations: CrmContextValidations
}

interface CrmStateValue {
  crmState: State
  setCrmState: (state: Partial<State>) => void
  addGuestsToCrm: () => Promise<boolean>
  addedGuestsToCrm: () => boolean
}

export const CrmContext = createContext({} as CrmStateValue)

export const CrmStateProvider = ({ children }: Props) => {
  const [state, setState] = useSetState<State>({
    entityType: 'Contact',
    crmCheckedGuests: [],
    validations: {
      guestValidations: {},
      isValid: true,
      missingAccountName: false,
      missingCompanyName: false,
    },
  })

  const addGuestsToCrm = useCallback(async () => {
    const guestValidations: GuestValidations = {}
    let missingAccountName = false
    let missingCompanyName = false
    let isCtxValid = true

    const mappedGuests = state.crmCheckedGuests
      .filter(g => g.shouldAdd)
      .map(g => {
        const guestValidation: GuestValidation = { missingFirstName: false, missingLastName: false }
        const isContact = state.entityType === 'Contact'
        const isLead = state.entityType === 'Lead'

        if (!g.firstName) {
          guestValidation.missingFirstName = true
          isCtxValid = false
        }

        if (!g.lastName) {
          guestValidation.missingLastName = true
          isCtxValid = false
        }

        if (isLead && !g.company) {
          guestValidation.missingCompanyName = true
          missingCompanyName = true
          isCtxValid = false
        } else if (isContact && !g.accountName) {
          guestValidation.missingAccountName = true
          missingAccountName = true
          isCtxValid = false
        }

        guestValidations[g.email] = guestValidation

        return {
          email: g.email.toLowerCase(),
          firstName: g.firstName,
          lastName: g.lastName,
          accountId: isContact ? g.accountId : undefined,
          company: isLead ? g.company : undefined,
        }
      })

    setState({
      validations: {
        guestValidations,
        isValid: isCtxValid,
        missingAccountName,
        missingCompanyName,
      },
    })

    if (!isCtxValid) {
      return false
    }

    const promises = mappedGuests.map(g => {
      if (state.entityType === 'Contact') {
        return crmEntity.createContact(g as CreateContactPost)
      } else {
        return crmEntity.createLead(g as CreateLeadPost)
      }
    })
    await Promise.all(promises)
    return true
  }, [state.crmCheckedGuests, state.entityType])

  return (
    <CrmContext.Provider
      value={{
        crmState: state,
        setCrmState: setState,
        addGuestsToCrm,
        addedGuestsToCrm: () => state.crmCheckedGuests.some(g => g.shouldAdd),
      }}
    >
      {children}
    </CrmContext.Provider>
  )
}
