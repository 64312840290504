import {
  intercomService,
  workspaces,
  bookMe,
  booker,
  IntercomBookedParams,
} from '@chilipiper/service/src/service'
import { BookRequest, BookWithMe, GetInfoDataType } from '@chilipiper/api-type-def'
import { FixedBookRequest } from '../types'

const postWindowMessage = (action: string, args?: unknown) => {
  const data = args || []
  window.parent && window.parent.postMessage({ action, args: data }, '*')

  // Opener is for Outlook Add-in and focused-inbox
  if (window.parent && window.parent.opener) {
    window.parent.opener.postMessage({ action, data }, '*')
  }
}

export const sendIntercomBooked = (data: IntercomBookedParams) => {
  return intercomService.booked(data)
}

export const selectWorkspace = (workspaceId: string) => {
  return workspaces.select(workspaceId)
}

export const bookWithMeMeeting = (data: FixedBookRequest) => {
  return bookMe.book(data as unknown as BookRequest)
}

export const bookReassign = (data: FixedBookRequest) => {
  return booker.reassign(data as unknown as BookRequest)
}

export const isSlotBusy = (data: FixedBookRequest) => {
  const info = {
    assigneesIds: [data.assigneeId],
    end: data.endDate,
    guests: data.bookerInfo.guests,
    queueId: data.queueId,
    roomIds: data.bookerInfo.roomsIds,
    start: data.startDate,
    step: data.bookerInfo.step,
    template: data.template,
    timezone: data.timezone,
    withBuffers: true,
  } as unknown as GetInfoDataType
  return bookMe
    .lastMomentCheckWithoutGuestSession(info)
    .then(({ busy }) => busy)
    .catch(() => false)
}

export const bookMeeting = (data: FixedBookRequest) => {
  return booker.book(data as unknown as BookRequest)
}

export const sendPingMessage = () => {
  postWindowMessage('ping')
}

export const insertAvailability = (data: BookWithMe, locale: string, callbackId?: string) => {
  postWindowMessage('insertAvailability', [callbackId, { tokenInfo: data, locale }])
}

export const insertAvailabilityAsHtml = (html: string, callbackId?: string) => {
  postWindowMessage('insertAvailability', [callbackId, { html }])
}

export const registerInBackground = () => {
  postWindowMessage('registerBookerPopup')
}

export const sendDidBookMessage = () => {
  postWindowMessage('meetingDidBook')
}

export const closePopup = () => {
  window.parent.close()
}
