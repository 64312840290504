import { Invitee } from '@chilipiper/api-type-def'
import { Guest } from '../../types'
import { OutreachProspect } from '../../types/index'
import { State } from '.'

export const hasGuest = (state: State, email: string) => {
  return state.assignee?.email === email || state.guests.some(g => g.email === email)
}

export const inviteeOrStringToGuest = (guest: Invitee | string): Guest => ({
  email: typeof guest === 'string' ? guest.toLowerCase() : (guest as Invitee).email.toLowerCase(),
  mandatory: true,
  fullName: '',
  inCrm: false, // this is a default value, check is done later in the flow
  checked: true,
})

export const outreachProspectToGuest = (prospect: OutreachProspect): Guest => ({
  email: prospect.email.toLowerCase(),
  mandatory: true,
  fullName: prospect.name,
  inCrm: false, // this is a default value, check is done later in the flow
  checked: true,
})
