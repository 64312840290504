import { useQueries, useQuery, useQueryClient } from 'react-query'
import {
  workspaces,
  booker,
  crmEntity,
  participant,
  admin,
  templates,
  personal,
  timezoneService,
  reports,
  licenses,
} from '@chilipiper/service/src/service'
import { eventsService, roomsService } from '@chilipiper/service/src/events'
import { post } from '@chilipiper/service/src/api'
import { crmService } from '@chilipiper/service/src/crm'
import { useDebounce } from 'use-debounce'
import {
  BookerCalendarEvent,
  BookerInfoResponse,
  CalendarType,
  MeetingSource,
  ObjectRelate,
  Prospect,
  Queue,
  RoomAvailability,
  UserSettings,
} from '@chilipiper/api-type-def'
import { BookerRulesParam, HandoffCalendarParam } from '@chilipiper/api-type-def/src/payload/booker'
import { TeamUsersParam } from '@chilipiper/api-type-def/src/payload/workspaces'
import { CrmProspect, CrmUser } from '@chilipiper/api-type-def/src/response/crm'
import omit from 'lodash/omit'
import { add, endOfDay } from 'date-fns'
import { Session, WorkspaceFeatureType, Workspaces } from '@chilipiper/models'
import { pick, uniqBy } from 'lodash'
import partition from 'lodash/partition'
import { Guest, QueueMember, QueueWithRules, WorkspaceWithRole } from '../types'
import { useBookingState, useGlobalState } from './context'
import { isEventWorkspace } from '../utils/workspaces'
import { getRoomId } from '../utils/reports'
import {
  getCalendarPayloadFromState,
  getPayloadStep,
  slotsPayloadToCalendarPayload,
} from '../utils/calendar'
import { getHandoffMembers, objectRelateToType } from '../utils/queues'
import { buildParseData } from '../pages/booker/helpers'

const DEFAULT_QUERY_DELAY = 500
interface WorkspaceRoles {
  [key: string]: {
    role: string
  }
}

export const usePersonalSettings = (fromAssignee?: boolean) => {
  const { globalState } = useGlobalState()
  const { bookingState } = useBookingState()
  const userId = (fromAssignee ? bookingState.assignee?.id : globalState.session.id) || ''
  return useQuery(['personal-settings', userId], () => personal.getUserSettings(userId), {
    enabled: userId.length > 0,
  })
}

export const useWorkspaces = () => {
  const { data: settings } = usePersonalSettings()
  const { bookingState } = useBookingState()
  return useQuery(
    ['workspaces', bookingState.handoff],
    () => {
      if (bookingState.handoff) {
        return workspaces.userWorkspacesHandoff()
      }
      return workspaces.userWorkspacesVisible().then(workspaces => {
        const roles = (settings && settings.roles) || ({} as UserSettings['roles'])
        const workspaceRoles = roles.reduce((acc, current) => {
          acc[current.workspaceId] = { role: current.role }
          return acc
        }, {} as WorkspaceRoles)
        const mappedWorkspaces: WorkspaceWithRole[] = workspaces
          .map(workspace => {
            return {
              ...workspace,
              isBookingOnSelfCalendar: workspaceRoles[workspace.id]?.role === 'Assignee',
            }
          })
          .filter(workspace => {
            return !workspace.isDistribution
          })
        return mappedWorkspaces
      })
    },
    { enabled: settings !== undefined }
  )
}

export const useWorkspaceQueues = () => {
  const { globalState } = useGlobalState()
  const { bookingState } = useBookingState()
  const workspaceId = bookingState.selectedWorkspace?.id
  return useQuery<QueueWithRules[]>(
    [
      'queues',
      workspaceId,
      bookingState.prospect?.id || 'no-prospect',
      bookingState.handoff,
      bookingState.selectedRelatedTo?.id,
    ],
    () => {
      return booker.queues
        .activeByWorkspace(workspaceId as string)
        .then(queues => {
          /*
          Queues have rules, so if there is a prospect with an id (known prospect in CRM) we check with this endpoint which queues
          have a "success" property - meaning the queue rules match
        */
          const queuesWithoutSuccess: QueueWithRules[] = queues.map(queue => ({
            ...queue,
            success: false,
            owners: [],
          }))
          if (bookingState.prospect?.id) {
            const bookerRulesParams: BookerRulesParam = {
              id: bookingState.prospect?.id,
              isLead: !!bookingState.prospect?.isLead,
              queues: queues
                .filter(queue => (bookingState.handoff ? queue.hotHandoff : true))
                .map(queue => queue.id),
            }
            const predefined = Object.assign(
              {
                caseId: globalState.caseId ?? '',
                opportunityId: globalState.opportunityId ?? '',
                customObjectId: globalState.customObjectId ?? '',
              },
              bookingState.selectedRelatedTo
                ? {
                    [objectRelateToType(bookingState.selectedRelatedTo.type)]:
                      bookingState.selectedRelatedTo.id,
                  }
                : {}
            )
            if (Object.values(predefined).some(value => !!value)) {
              bookerRulesParams.predefined = predefined
            }
            return booker
              .rules(bookerRulesParams)
              .then(rules => {
                const newQueues: QueueWithRules[] = rules.map(rule => ({
                  ...rule,
                  ...(queues.find(queue => queue.id === rule.id) as Queue),
                }))
                return newQueues
              })
              .catch(() => {
                return queuesWithoutSuccess
              })
          } else {
            return queuesWithoutSuccess
          }
        })
        .then(queues => {
          /*
            Events workspaces have a bug where All Attendees queue is duplicated
            and is shown as valid queue
          */
          return queues.filter(queue => {
            return (
              queue.name !== 'All Attendees' &&
              (queue.algorithm === 'Group' ? !queue.isGroupBookingLink : true)
            )
          })
        })
    },
    { enabled: workspaceId !== undefined }
  )
}

export const useWorkspaceEvent = () => {
  const { bookingState } = useBookingState()
  const workspaceId = bookingState.selectedWorkspace?.id
  return useQuery(
    ['workspace-events', workspaceId],
    () => {
      if (!isEventWorkspace(bookingState.selectedWorkspace) || !workspaceId) {
        return Promise.resolve(undefined)
      }
      return eventsService.getEventsByWorkspaceId(workspaceId).then(events => {
        return events[0]
      })
    },
    { enabled: workspaceId !== undefined }
  )
}

export const useWorkspacesRooms = () => {
  const { bookingState, isEventWorkspace } = useBookingState()
  const workspaceId = bookingState.selectedWorkspace?.id
  const isEventsWorkspace = isEventWorkspace()
  const { data: event, isLoading: loadingEvents } = useWorkspaceEvent()
  const selections = bookingState.calendarSelections

  const filterRoomsByCurrentQueue = (room: RoomAvailability) => {
    return room.queues.includes(bookingState.selectedQueue?.id || '')
  }
  /*
    If Any room is selected, we don't want to update the room availability
    because FE takes care of that on Calendar
  */
  return useQuery(
    ['workspace-room', workspaceId, selections, event?.id, bookingState.selectedQueue?.id],
    () => {
      if (!event) {
        return Promise.resolve([])
      } else {
        if (!selections.length) {
          const date = new Date().valueOf()
          return roomsService.availability(date, date, event?.id as string).then(rooms => {
            return rooms
              .map(room => {
                return { ...room, isAvailable: true }
              })
              .filter(filterRoomsByCurrentQueue)
          })
        } else {
          const promises = selections.map(selection => {
            return roomsService.availability(
              selection.start.valueOf(),
              selection.end.valueOf(),
              event?.id as string
            )
          })
          return Promise.all(promises).then(availabilities => {
            const rooms = availabilities[0]
            return rooms
              .map(room => {
                return {
                  ...room,
                  isAvailable: availabilities.every(rooms => {
                    return rooms.find(r => r.id === room.id)?.isAvailable
                  }),
                }
              })
              .filter(filterRoomsByCurrentQueue)
          })
        }
      }
    },
    { enabled: workspaceId !== undefined && isEventsWorkspace && !loadingEvents }
  )
}

export const useRoomSlots = () => {
  const { bookingState, isAnyRoom, isEventWorkspace } = useBookingState()
  const { data: rooms } = useWorkspacesRooms()
  const { data: slots = [] } = useSlots()
  const roomId = getRoomId(bookingState.selectedRoom)

  const buildSlotPromise = (roomsIds: string[]) => {
    return post('rooms/slots', {
      json: {
        eventId: bookingState.selectedEvent?.id,
        roomsIds: roomsIds,
        start: bookingState.start.valueOf(),
        end: bookingState.end.valueOf(),
        step: bookingState.step,
      },
    }).json<BookerCalendarEvent[]>()
  }

  const removeBusySlots = (slots: BookerCalendarEvent[], roomBusySlots: BookerCalendarEvent[]) => {
    return slots.filter(slot => {
      const hasBusyRoomSlot = roomBusySlots.find(
        roomSlot => roomSlot.start === slot.start && roomSlot.end === slot.end
      )
      return !hasBusyRoomSlot
    })
  }

  return useQuery(['room-slots', roomId, slots, isAnyRoom()], () => {
    if (!isEventWorkspace() || !roomId) {
      return slots
    }
    if (isAnyRoom() && rooms?.length) {
      const roomsSlots = rooms.map(room => {
        return buildSlotPromise([room.id])
      })
      return Promise.all(roomsSlots).then(allSlots => {
        return slots?.filter(slot => {
          return allSlots.some(busySlots => {
            return !busySlots.some(s => s.start === slot.start && s.end === slot.end)
          })
        })
      })
    } else if (roomId && slots) {
      return buildSlotPromise([roomId]).then(busySlots => {
        return removeBusySlots(slots, busySlots)
      })
    }

    return [] as BookerCalendarEvent[]
  })
}

export const useQueueRoomOrder = () => {
  const { bookingState, isEventWorkspace } = useBookingState()
  const isEventsWorkspace = isEventWorkspace()
  const queue = bookingState.selectedQueue
  return useQuery(
    ['queue-room', queue?.id],
    () => {
      return roomsService.sortByQueue(queue?.id as string)
    },
    { enabled: queue !== undefined && isEventsWorkspace }
  )
}

export const useReportReassignData = () => {
  const { globalState } = useGlobalState()
  const reportId = globalState.reportId
  return useQuery(
    ['report-reassign', reportId],
    () => {
      return booker.findReassignData(reportId as string)
    },
    { enabled: reportId !== undefined }
  )
}

export const useFindMainGuestInCRM = () => {
  const {
    globalState: { id: accountId, caseId, leadId, session },
  } = useGlobalState()
  const {
    bookingState: { prospect },
  } = useBookingState()

  const prospectEmail = prospect?.email

  const id = accountId || leadId || (prospect?.isLead && prospect?.id)
  return useQuery(
    ['main-guest-crm-data', id, caseId, prospectEmail],
    () => {
      if (caseId) {
        return crmEntity.getCase(caseId).then(response => {
          const participantId = response.contactId
          return participant.byId(participantId)
        })
      }
      if (id) {
        return participant.byId(id)
      }
      return participant.byEmail(prospectEmail as string).then(participantByEmail => {
        // Hubspot integration takes longer to be able to fetch this data, so we need to retry
        if (participantByEmail.total === 0 && session.crm?.type === 'Hubspot') {
          throw new Error('Participant not found')
        }
        if (!participantByEmail.prospect.fullName) {
          participantByEmail.prospect.fullName = prospect?.fullName
        }
        if (!participantByEmail.prospect.companyName) {
          participantByEmail.prospect.companyName = prospect?.companyName
        }
        return participantByEmail
      })
    },
    {
      enabled: id !== undefined || prospectEmail !== undefined || caseId !== undefined,
      retry: session.crm?.type === 'Hubspot',
      retryDelay: 5000,
    }
  )
}

export const useRefetchSlots = () => {
  const queryClient = useQueryClient()
  return async () => {
    await queryClient.invalidateQueries(['calendar'])
    await queryClient.invalidateQueries(['working-hours-slots'])
  }
}

export const useRefetchGuestsInfo = () => {
  const queryClient = useQueryClient()
  return async () => {
    Promise.all([
      // Resetting so it could retry according to the policies
      queryClient.resetQueries(['guest-info']),
      queryClient.resetQueries(['main-guest-crm-data']),
    ])
  }
}

export const useGuestsInfo = () => {
  const {
    globalState: { session },
  } = useGlobalState()
  const { bookingState } = useBookingState()
  const guests = bookingState.guests.concat(
    bookingState.prospect ? [bookingState.prospect as Guest] : []
  )
  const guestsWithoutFullData = guests.filter(guest => !guest.id && !guest.source)
  const guestsInfo = useQueries(
    guestsWithoutFullData.map(guest => ({
      queryKey: ['guest-info', guest.email],
      queryFn: () => participant.findInCrm(guest.email.toLowerCase()),
      retry: session.crm?.type === 'Hubspot' ? 1 : 0,
      retryDelay: 5000,
    }))
  )
  const onCrm: Prospect[] = guestsInfo.reduce((acc, { data: prospect }) => {
    if (prospect) {
      acc.push(prospect)
    }
    return acc
  }, [] as Prospect[])
  const key = guests.map((guest: Guest) => guest.id ?? guest.email).join(',')
  return useQuery(
    ['guests-info', key],
    () => {
      return guests.map(guest => {
        return {
          ...guest,
          ...onCrm.find(g => guest.email === g.email),
        }
      })
    },
    { enabled: guests.length > 0 && guestsInfo.every(query => query.status !== 'loading') }
  )
}

export const useRelateTo = () => {
  const { bookingState } = useBookingState()

  const accountId = bookingState.prospect?.accountId

  return useQuery(
    ['related-to', accountId],
    () => {
      return booker.relatedTo(accountId as string)
    },
    { enabled: accountId !== undefined }
  )
}

export const useDaysToExpire = () => {
  return useQuery(['days-to-expire'], () => {
    return admin.daysToExpire()
  })
}

export const useTemplates = () => {
  const { bookingState } = useBookingState()
  const workspaceId = bookingState.selectedWorkspace?.id
  return useQuery(['templates', workspaceId], () => {
    return templates.getAll().then(templates => {
      return templates.filter(template => template.active)
    })
  })
}

export const useTemplate = (id?: string) => {
  return useQuery(
    ['template', id],
    () => {
      return templates.byId(id as string)
    },
    { enabled: id !== undefined }
  )
}

export const useCalendar = () => {
  const { globalState } = useGlobalState()
  const { bookingState, isEventWorkspace } = useBookingState()
  const payload = getCalendarPayloadFromState(bookingState, globalState)
  const queryKey = bookingState.showAvailabilityForAll
    ? pick(payload, 'template', 'queueId', 'guests')
    : payload.guests
  return useQuery(
    [
      'calendar',
      bookingState.start,
      bookingState.end,
      payload.assigneesIds,
      queryKey,
      bookingState.workspaceId,
      bookingState.showAvailabilityForAll,
    ],
    () => {
      /* We return empty events instead of disabling the query because QueryController expects all queries to finish */
      if (
        (bookingState.showAvailabilityForAll && !bookingState.workspaceId) ||
        payload.assigneesIds.length === 0 ||
        isEventWorkspace()
      ) {
        return Promise.resolve({ events: [] })
      }
      if (bookingState.showAvailabilityForAll) {
        return booker.calendarSlots(payload)
      } else {
        const originalEventsPayload = slotsPayloadToCalendarPayload(
          payload,
          bookingState.selectedWorkspace?.id as string
        )
        return booker.calendar(originalEventsPayload).then(data => {
          const events = data.events
            .filter(event => event.isBusy)
            .map(event => {
              const isEndOfDay = event.end === endOfDay(event.start).valueOf() + 1
              if (isEndOfDay) {
                event.end -= 1
              }
              return event
            })

          const [eventsWithTitles, eventsWithNoTitles] = partition(events, 'title')

          // API can return duplicated events, and they look different on UI due to background transparency.
          const uniqEventsWithTitles = uniqBy(eventsWithTitles, event =>
            [event.start, event.title].join()
          )

          return {
            events: [...uniqEventsWithTitles, ...eventsWithNoTitles],
          }
        })
      }
    }
  )
}

export const useEventCalendar = () => {
  const { bookingState } = useBookingState()
  const eventId = bookingState.selectedEvent?.id
  return useQuery(['event-calendar', eventId], () => {
    if (!eventId) {
      return Promise.resolve([])
    }
    return reports.byEventId(eventId as string)
  })
}

export const useSlots = () => {
  const { bookingState, isEventWorkspace } = useBookingState()
  const { globalState } = useGlobalState()
  const { data: workspaces, isLoading: isLoadingWorkspaces } = useWorkspaces()
  const workspacesLength = isLoadingWorkspaces || !workspaces ? 0 : workspaces.length
  const hasSelectedWorkspace = workspacesLength > 0 && bookingState.selectedWorkspace !== undefined
  const isLoadingEvents = isEventWorkspace() && !bookingState.selectedEvent
  const payload = {
    ...getCalendarPayloadFromState(bookingState, globalState),
    source: 'Booker' as MeetingSource,
    slot: getPayloadStep(bookingState.step),
    withAvailableDays: true,
  }

  return useQuery(
    ['working-hours-slots', payload],
    () => {
      return booker.workingHoursSlots(payload)
    },
    {
      enabled:
        (hasSelectedWorkspace ||
          (!isLoadingWorkspaces && workspacesLength === 0) ||
          Session.isFreeUser()) &&
        payload.template !== undefined &&
        !bookingState.isLoadingQueues &&
        !isLoadingEvents &&
        payload.assigneesIds.length > 0,
    }
  )
}

export const useWorkspacePreferences = () => {
  const { bookingState } = useBookingState()
  const workspaceId = bookingState.selectedWorkspace?.id
  return useQuery(
    ['preferences', workspaceId],
    () => {
      return booker.workspacePreferences(workspaceId as string)
    },
    { enabled: workspaceId !== undefined }
  )
}

export const useSearchAccounts = (entitySearchName: string) => {
  const [debouncedQuery] = useDebounce(entitySearchName, DEFAULT_QUERY_DELAY)
  return useQuery(['search-accounts', debouncedQuery], () => {
    if (!entitySearchName) {
      return []
    }
    return crmEntity.searchAccounts(entitySearchName)
  })
}

export const useAssigneeTimezone = () => {
  const { bookingState } = useBookingState()
  const userId = bookingState.assignee?.id
  return useQuery(['timezone', userId], () => timezoneService.userSettings(userId as string), {
    enabled: userId !== undefined,
  })
}

export const useWorkspaceUsers = (params: TeamUsersParam) => {
  const [debouncedQuery] = useDebounce(params.query, DEFAULT_QUERY_DELAY)
  return useQuery(
    ['workspace-users', params.workspaceId, debouncedQuery],
    () => {
      return workspaces.users(params)
    },
    { enabled: params.workspaceId !== undefined }
  )
}
/* Queue can be empty since hook is only enabled with id */
export const useQueueMembers = () => {
  const { bookingState } = useBookingState()
  const queue = bookingState.selectedQueue || ({} as QueueWithRules)
  const assignees = queue?.members?.map(m => m.id) || []
  const template = bookingState.selectedTemplate
  const params = {
    assigneesIds: assignees,
    start: bookingState.start.valueOf(),
    end: bookingState.end.valueOf(),
    step: bookingState.step,
    queueId: queue?.id || '',
    guests: bookingState.guests.map(guest => guest.email),
    template: template?.id || '',
    withBuffers: assignees.length > 1,
    withAvailableDays: !!bookingState.handoff,
  }

  const shouldEnableQuery = params.template !== ''

  const handoffPayload: HandoffCalendarParam = {
    ...omit(params, ['guests', 'template', 'withBuffers']),
    start: new Date().getTime(),
    end: add(new Date(), { minutes: template?.duration || 30 }).valueOf(),
    withAvailableDays: true,
  }

  return useQuery<QueueMember[]>(
    ['queue-info', queue.id, bookingState.handoff],
    () => {
      if (queue.id === undefined) {
        return Promise.resolve([])
      }
      return booker.info(params).then(info => {
        const members = queue.members
          .map(member => {
            const memberInfo = info.find(i => i.id === member.id) || ({} as BookerInfoResponse)
            return {
              ...member,
              ...memberInfo,
            }
          })
          .filter(m => m.email)

        if (bookingState.handoff) {
          return booker.handoffCalendar(handoffPayload).then(events => {
            const busyIds = events.events.flatMap(e => e.assigneeIds)
            return members.map(member => {
              return {
                ...member,
                available: busyIds.every(id => id !== member.id),
              }
            })
          })
        }
        return members
      })
    },
    { enabled: shouldEnableQuery }
  )
}

export const useHandoffMembers = () => {
  const { data: queueMembers = [] } = useQueueMembers()

  return getHandoffMembers(queueMembers)
}

export const useParseTemplate = () => {
  const { globalState } = useGlobalState()
  const { bookingState } = useBookingState()
  const assigneeId = bookingState.assignee?.id || (globalState.session.user.id as string)
  const parseData = buildParseData({
    template: bookingState.selectedTemplate,
    assigneeId,
    prospectId: bookingState.prospect?.id,
    rawProspect: bookingState.prospect,
    eventId: bookingState.selectedEvent?.id,
    roomId: getRoomId(bookingState.selectedRoom),
    accountId: bookingState.prospect?.accountId,
    caseId:
      bookingState.selectedRelatedTo?.type === ObjectRelate.Case
        ? bookingState.selectedRelatedTo?.id
        : globalState.caseId,
    opportunityId:
      bookingState.selectedRelatedTo?.type === ObjectRelate.Opportunity
        ? bookingState.selectedRelatedTo?.id
        : globalState.opportunityId,
  })

  const params = {
    list: parseData,
  }

  const hasTemplateSet = params.list.some(param => param.template)
  return useQuery(
    ['template-parse', params],
    () => {
      return templates.parse(params)
    },
    { enabled: hasTemplateSet && !bookingState.isLoadingQueues }
  )
}

export const useGuestSearch = (query: string) => {
  const delay = query !== '' ? DEFAULT_QUERY_DELAY : 0
  const [debouncedQuery] = useDebounce(query, delay)
  const { bookingState } = useBookingState()
  const workspaceType =
    (bookingState.selectedWorkspace &&
      Workspaces.workspaceFeatureType(bookingState.selectedWorkspace)) ??
    WorkspaceFeatureType.Free

  return useQuery(
    ['guest-search', debouncedQuery],
    () => {
      return Promise.allSettled([
        crmService.search.users(query),
        crmService.search.prospects(query),
      ]).then(promises => {
        let users: Guest[] = []
        promises.forEach(promise => {
          if (promise.status === 'fulfilled') {
            const mapped: Guest[] = (promise.value as Array<CrmUser | CrmProspect>).map(
              (user: CrmUser | CrmProspect) => ({
                ...user,
                inCrm: (user as CrmProspect).inCrm || false,
                fullName: user.fullName,
                mandatory: true,
              })
            )
            users = users.concat(mapped)
          }
        })
        return users
      })
    },
    { enabled: query.length > 0 && workspaceType !== WorkspaceFeatureType.Free }
  )
}

export const useQueue = () => {
  /*
    The IB endpoint to get queues does not return their settings, so this
    hook can be used to get all the information of a queue
  */
  const { bookingState } = useBookingState()
  const queueId = bookingState.selectedQueue?.id

  return useQuery(
    ['queue', queueId],
    () => {
      return booker.queues.get(queueId as string)
    },
    { enabled: queueId !== undefined }
  )
}

export const useMemberLink = (memberId: string) => {
  return useQuery(['member-link', memberId], () => {
    return personal.getUserLink(memberId)
  })
}

interface UseGuestCalConnectionStatusProps {
  email: string
}

interface GuestCalConnectionStatus {
  calConnectionStatus?: { google: boolean; microsoft: boolean }
  isCPUser: boolean
}

export const useGuestCalConnectionStatus = ({ email }: UseGuestCalConnectionStatusProps) => {
  return useQuery<GuestCalConnectionStatus>(['guest-licenses', email], () => {
    return licenses
      .table({ filters: { search: email }, page: 0, pageSize: 1 })
      .then(({ results }) => {
        if (!results.length) {
          return { isCPUser: false }
        }

        const user = results[0]
        const isConnected = user.calendarStatus === 'Connected'
        const isGoogle = user.calendarType === CalendarType.Google
        const isMicrosoft = user.calendarType === CalendarType.Microsoft
        return {
          isCPUser: true,
          calConnectionStatus: {
            microsoft: isMicrosoft && isConnected,
            google: isGoogle && isConnected,
          },
        }
      })
  })
}
