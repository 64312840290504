import React, { createContext } from 'react'
import { Session } from '@chilipiper/api-type-def'
import useSetState from 'react-use/lib/useSetState'
import { Session as SessionModel } from '@chilipiper/models/src/session'
import { getQueryParam, getQueryParamBoolean } from '../../utils/query-param'
import { SIDEBAR_WIDTH } from '../../utils/constants'

type SearchBy = 'id' | 'email'
type InsertReturnFormat = 'html' | 'htmlInline'

export interface State {
  callbackId?: string
  caseId?: string
  id?: string
  inIntercom?: boolean
  intermediaryScreen: boolean
  skipIntermediaryScreen: boolean
  isExtension?: boolean
  isLead?: boolean
  insertReturnFormat?: InsertReturnFormat
  isOutreach?: boolean
  isOutlook?: boolean
  sidebarWidth: number
  leadId?: string
  ticketId?: string
  objectId?: string
  code?: string
  onboardingFlow?: boolean
  popup?: boolean
  prospectIdSupplied?: string
  opportunityId?: string
  customObjectId?: string
  dealId?: string
  reportId?: string
  searchBy?: SearchBy
  selectedGoogleTime?: string
  session: Session
  shouldReloadProspect?: boolean
  showSecondTimezone?: boolean
  source?: string
  suggestedTimes?: boolean
  withoutProspect?: boolean
  skipWorkspaceRedirect?: boolean
}

interface GlobalStateValue {
  globalState: State
  setGlobalState: (state: Partial<State>) => void
}

export const GlobalContext = createContext({} as GlobalStateValue)

interface Props {
  initialState?: Partial<State>
  children: React.ReactNode
}

export const GlobalStateProvider: React.FC<Props> = ({ children, initialState }) => {
  /*
    Salesforce sometimes sends id=Case or id=Account in query param, and it conflicts
    to our logic to fetch prospect from id query param, so we need to ignore these cases
  */
  const getId = () => {
    const id = getQueryParam('id', '') as string
    if (id.toLowerCase() === 'case' || id.toLowerCase() === 'account') {
      return ''
    }
    return id
  }
  const [state, setState] = useSetState<State>({
    intermediaryScreen: false,
    skipIntermediaryScreen: getQueryParamBoolean('skipIntermediaryScreen'),
    code: getQueryParam('code'),
    suggestedTimes: getQueryParamBoolean('bookWithMe'),
    popup: getQueryParamBoolean('popup'),
    searchBy: getQueryParam('searchBy') as SearchBy,
    onboardingFlow: getQueryParamBoolean('onboardingFlow'),
    isExtension: getQueryParamBoolean('isExtension'),
    isLead: getQueryParamBoolean('isLead'),
    id: getId(),
    withoutProspect: getQueryParamBoolean('withoutProspect'),
    callbackId: getQueryParam('callbackId'),
    insertReturnFormat: getQueryParam('insertReturnFormat') as InsertReturnFormat,
    isOutreach: getQueryParamBoolean('isOutreach'),
    isOutlook: getQueryParamBoolean('isOutlook'),
    reportId: getQueryParam('reportId'),
    ticketId: getQueryParam('ticketId'),
    leadId: getQueryParam('leadId'),
    objectId: getQueryParam('objectId'),
    caseId: getQueryParam('caseId'),
    opportunityId: getQueryParam('opportunityId'),
    customObjectId: getQueryParam('customObjectId'),
    dealId: getQueryParam('dealId'),
    session: SessionModel.createEmptySession(),
    sidebarWidth: SIDEBAR_WIDTH,
    skipWorkspaceRedirect: getQueryParamBoolean('skipWorkspaceRedirect'),
    showSecondTimezone: true,
    source: getQueryParam('source'),
    ...initialState,
  })

  const contextValue = {
    globalState: state,
    setGlobalState: setState,
  }

  return <GlobalContext.Provider value={contextValue}>{children}</GlobalContext.Provider>
}
