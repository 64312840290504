export const routes = {
  error: {
    session: '/login-error',
    noProspect: '/no-prospect-error',
    general: '/error',
    canceled: '/meeting-canceled',
  },
  duplicateRecords: '/duplicate-records',
  booked: '/booked',
  booker: '/booker',
  handoff: '/handoff',
  intermediary: '/intermediary-screen',
}
