import { View } from 'react-big-calendar'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import { getClientTimezone } from '@chilipiper/date-time'

const clientTimezone = getClientTimezone()

export const convertTimezoneToLocal = (date: Date | number, timezone: string) => {
  return utcToZonedTime(date, timezone)
}

export const convertTimezone = (date: Date | number, timezone: string) => {
  return zonedTimeToUtc(date, timezone)
}

export const shouldShowSecondTimezone = (userTimezone: string, viewType: View) => {
  const now = new Date()
  return (
    convertTimezoneToLocal(now, clientTimezone).getTime() !==
      convertTimezoneToLocal(now, userTimezone).getTime() && viewType !== 'month'
  )
}
