import React, { Suspense } from 'react'
import { DefaultLoading } from './DefaultLoading'

interface Props {
  loadingComponent?: React.ReactElement
  children?: React.ReactElement
}

export function SuspenseWithFallback({ loadingComponent, children }: Props) {
  const renderLoading = () => {
    if (loadingComponent) {
      return loadingComponent
    }
    return <DefaultLoading />
  }
  return <Suspense fallback={renderLoading()}>{children}</Suspense>
}
