export const NAVIGATION_WIDTH = 310
export const SIDEBAR_WIDTH = 320
export const SIDEBAR_WIDTH_EXPANDED = 460
export const easeOutQuint = 'cubic-bezier(0.22, 1, 0.36, 1)'
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const zIndex = {
  CALENDAR_OVERLAY: 10,
  CALENDAR_LOADING: 8,
  SETTINGS_DROPDOWN: 7,
  AVAILABILITY_FOR_ALL_LEGEND: 7,
  LOCATION_DROPDOWN: 7,
  TOOLBAR: 6,
  EXPANDER: 5,
  WORKSPACE_DROPDOWN: 2,
  QUEUE_DROPDOWN: 1,
}

export const crmSource = {
  Hubspot: 'Hubspot CRM',
  Salesforce: 'Salesforce',
  User: 'User',
  Google: 'Google',
  Microsoft: 'Microsoft',
  Manual: 'Manual',
}
