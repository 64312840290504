import { isAfter, isBefore, isEqual } from 'date-fns'
import { CalendarEvent } from '../types'

export const isSameOrBefore = (date1: Date | number, date2: Date | number) => {
  return isEqual(date1, date2) || isBefore(date1, date2)
}

export const isSameOrAfter = (date1: Date | number, date2: Date | number) => {
  return isEqual(date1, date2) || isAfter(date1, date2)
}

export const hasSameTime = (event1: CalendarEvent, event2: CalendarEvent) => {
  return (
    event1.start.getTime() === event2.start.getTime() &&
    event1.end.getTime() === event2.end.getTime()
  )
}

export const compareIsoDates = (a: string, b: string) => {
  if (a < b) {
    return 1
  } else if (a > b) {
    return -1
  }
  return 0
}
